import { useForm } from 'react-hook-form';
import LabelForm from './LabelFrom';
import { useState } from 'react';
import { Puff } from 'react-loader-spinner'
import { petSizeslist, preciosTlcPalmBeach, preciosTlcPrincipal, servicesList } from '../providers/prices';
import '../App.css';
import logoTLC from '../assets/images/logo-black.webp'
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

export default function FormularioRegistro() {

    const [isLoading, setIsLoading] = useState(false)
    const [ estimatePrice, setEstimatePrice ] = useState('Choose county, size and service')

    const { register, handleSubmit, formState: { errors }, watch, setValue, reset, setError, clearErrors } = useForm({
        defaultValues: {
            date: formatearFecha(new Date()),
            time: '',
            price: 0,
            petType: 'Dog'
        },
    })

    const onSubmit = handleSubmit(datos => {
        if (watch('size') === null) {
            setError("size", { type: "custom", message: "Choose the size of your pet" })
            return
        }
        if (watch('service') === null) {
            setError("service", { type: "custom", message: "Choose the type of service" })
            return
        }

        let datosUser = new FormData();
        datosUser.append('Fecha Solicitud', new Date())
        for (const key in datos) {
            switch (key) {
                case 'date': datosUser.append('Fecha', datos[key])
                    break;
                case 'time': datosUser.append('Hora', datos[key])
                    break;
                case 'name': datosUser.append('Nombre', datos[key])
                    break;
                case 'lastName': datosUser.append('Apellido', datos[key])
                    break;
                case 'cellPhone': datosUser.append('Celular', datos[key])
                    break
                case 'email': datosUser.append('Correo', datos[key])
                    break;
                case 'zipCode': datosUser.append('Zip Code', datos[key])
                    break;
                case 'promo': datosUser.append('Promo', datos[key])
                    break;
                case 'price': datosUser.append('Total', datos[key])
                    break;
                case 'petSize': datosUser.append('Size', datos[key])
                    break;
                case 'service': datosUser.append('Servicio', datos[key])
                    break;
                case 'address': datosUser.append('Dirección', datos[key])
                    break;
                case 'petType': datosUser.append('Tipo', datos[key])
                    break;
                case 'county':
                    if (datos[key] === 'Broward') {
                        datosUser.append('hoja_destino', 'TLC Broward')
                    } else if (datos[key] === 'Miami') {
                        datosUser.append('hoja_destino', 'TLC Broward')
                    } else if (datos[key] === 'Palm Beach') {
                        datosUser.append('hoja_destino', 'TLC Palm Beach')
                    } else if (datos[key] === 'Fort Myers') {
                        datosUser.append('hoja_destino', 'Fort Myers')
                    }
                    break;

                default:
                    break;
            }
        }
        const scriptURL = 'https://script.google.com/macros/s/AKfycbyp760B8Z8BPzWjtHOSqWGYsC4fzKb6aTedgKdVMoRn1UGhrjSAIbsEmLyh1JReyWRXoQ/exec'
        setIsLoading(true)
        fetch(scriptURL, { method: 'POST', body: datosUser })
            .then(response => response.json()).then(data => {
                if (data.result === 'success') {
                    reset()
                    setEstimatePrice('Choose county, size and service')
                    Swal.fire({
                        title: "Booking Successful",
                        text: "An agent will contact you soon to provide details.",
                        icon: "success"
                    });
                }
            })
            .catch(error => console.error('Error!', error.message))
            .finally(() => {
                setIsLoading(false)
            })

    })

    function updatePrices(value) {
        let objPrecios = {};
        switch (watch('county')) {
            case 'Broward':
                objPrecios = {
                    basicGrooming: preciosTlcPrincipal[watch('petType')][value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPrincipal[watch('petType')][value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPrincipal[watch('petType')][value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            case 'Miami':
                objPrecios = {
                    basicGrooming: preciosTlcPrincipal[watch('petType')][value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPrincipal[watch('petType')][value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPrincipal[watch('petType')][value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            case 'Fort Myers':
                objPrecios = {
                    basicGrooming: preciosTlcPrincipal[watch('petType')][value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPrincipal[watch('petType')][value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPrincipal[watch('petType')][value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            case 'Palm Beach':
                objPrecios = {
                    basicGrooming: preciosTlcPalmBeach[watch('petType')][value]?.basicGrooming ?? { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: preciosTlcPalmBeach[watch('petType')][value]?.deShedding ?? { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: preciosTlcPalmBeach[watch('petType')][value]?.fullGrooming ?? { price: 0, estimated: 'Choose county, size and service' }
                }
                break;
            default:
                objPrecios = {
                    basicGrooming: { price: 0, estimated: 'Choose county, size and service' },
                    deShedding: { price: 0, estimated: 'Choose county, size and service' },
                    fullGrooming: { price: 0, estimated: 'Choose county, size and service' }
                }
                break
        }
        if (watch('service') === 'basic-grooming') {
            setValue('price', objPrecios.basicGrooming.price)
            setEstimatePrice(objPrecios.basicGrooming.estimated)
        } else if (watch('service') === 'full-grooming') {
            setValue('price', objPrecios.fullGrooming.price)
            setEstimatePrice(objPrecios.fullGrooming.estimated)
        } else if (watch('service') === 'de-shedding') {
            setValue('price', objPrecios.deShedding.price)
            setEstimatePrice(objPrecios.deShedding.estimated)
        } else {
            setValue('price', 0)
            setEstimatePrice('Choose county, size and service')
        }
    }

    function formatearFecha(date) {
        let dia = date.getDate();
        let mes = date.getMonth() + 1; // Los meses comienzan desde 0
        let año = date.getFullYear();
      
        // Añade un cero si el día o mes es menor a 10
        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;
      
        return mes + '/' + dia + '/' + año;
    }

    return <form className='cont-form' onSubmit={onSubmit}>
        <div className='cont-text-header'>
            <img src={logoTLC} alt="Logo TLC Mobile Pet Grooming" />
        </div>
        <div className='cont-form-top-der'>
            <div className='cont-input-item'>
                <LabelForm name={'County'} icono={'county'} />
                <select {...register('county', {
                    required: {
                        value: true,
                        message: 'Select the county'
                    },
                    onChange: e => updatePrices(watch('petSize'))
                })}>
                    <option value=''>Select the county</option>
                    <option value='Broward'>Broward</option>
                    <option value='Miami'>Miami</option>
                    <option value='Palm Beach'>Palm Beach</option>
                    <option value='Fort Myers'>Fort Myers</option>
                </select>
                <span>{!!errors['county'] && errors['county'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Pet Type'} icono={'dog'} />
                <select {...register('petType', {
                    required: {
                        value: true,
                        message: 'Choose the type of your pet'
                    },
                    //onChange: e => updatePrices(e.target.value)
                })}>
                    <option value='Dog'>Dog</option>
                    <option value='Cat'>Cat</option>
                </select>
                <span>{!!errors['petSize'] && errors['petSize'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Pet Size'} icono={'paw'} />
                <select {...register('petSize', {
                    required: {
                        value: true,
                        message: 'Choose the size of your pet'
                    },
                    onChange: e => updatePrices(e.target.value)
                })}>
                    <option value=''>Choose the size</option>
                    { petSizeslist.map((size, index) => watch('petType') === size.type && <option key={ index } value={ size.clave }>{ size.name }</option>) }
                </select>
                <span>{!!errors['petSize'] && errors['petSize'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Service'} icono={'paw'} />
                <select {...register('service', {
                    required: {
                        value: true,
                        message: 'Select the service'
                    },
                    onChange: e => updatePrices(watch('petSize'))
                })}>
                    <option value=''>Select the service</option>
                    { servicesList.map((serv, index) => watch('petType') === serv.type && <option key={ index } value={ serv.clave }>{ serv.name }</option>) }
                </select>
                <span>{!!errors['service'] && errors['service'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Cell Phone'} icono={'cell'} />
                <input type='number' {...register('cellPhone', {
                    required: {
                        value: true,
                        message: 'The Cell Phone is required'
                    },
                    pattern: {
                        value: /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}$/,
                        message: "The phone number is not valid"
                    }
                })} />
                <span>{!!errors['cellPhone'] && errors['cellPhone'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Promo'} icono={'gift'} />
                <select {...register('promo', {
                    required: {
                        value: true,
                        message: 'Select the promo'
                    },
                })}>
                    <option value=''>Select the promo</option>
                    <option value='FREE Teeth Brushing'>FREE Teeth Brushing</option>
                    <option value='FREE Flea Dip'>FREE Flea Dip</option>
                </select>
                <span>{!!errors['promo'] && errors['promo'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'First Name'} icono={'person'} />
                <input type='text' {...register('name', {
                    required: {
                        value: true,
                        message: 'The name is required'
                    },
                })} />
                <span>{!!errors['name'] && errors['name'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Last Name'} icono={'person'} />
                <input type='text' {...register('lastName', {
                    required: {
                        value: true,
                        message: 'The Last Name is required'
                    }
                })} />
                <span>{!!errors['lastName'] && errors['lastName'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Day'} icono={'date'} />
                <DatePicker
                    selected={ watch('date') !== '' ? new Date(watch('date')) : new Date() }
                    onChange={(date) => {
                        try {
                            const fechaFormateada = formatearFecha(date);
                            setValue('date', fechaFormateada)
                            clearErrors('date')
                        } catch (error) {
                            setError('date', { type: 'custom', message: 'Select the day of your appointment' })
                        }
                    }}
                    includeDateIntervals={[
                        { start: new Date(), end: addDays(new Date(), 30) },
                    ]}
                    placeholderText="Select the day of your appointment"
                />
                <span>{!!errors['date'] && errors['date'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Address'} icono={'postal'} />
                <input type='text' {...register('address', {
                    required: 'Write the address where you require the service'
                })} />
                <span>{!!errors['address'] && errors['address'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Zip Code'} icono={'postal'} />
                <input type='number' {...register('zipCode', {
                    required: 'Enter the zip code'
                })} />
                <span>{!!errors['zipCode'] && errors['zipCode'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Time Frame'} icono={'time'} />
                <select {...register('time', {
                    required: {
                        value: true,
                        message: 'Select your appointment time'
                    },
                })}>
                    <option value=''>Select your appointment time</option>
                    <option value="AM (8:30am-11:45am)" >{ 'AM (8:30am-11:45am)' }</option>
                    <option value="PM (12:00pm-6:00pm)" >{ 'PM (12:00pm-6:00pm)' }</option>
                    <option value="Anytime (8:30am-6:00pm)" >{ 'Anytime (8:30am-6:00pm)' }</option>
                </select>
                <span>{!!errors['time'] && errors['time'].message}</span>
            </div>
            <div className='cont-input-item'>
                <LabelForm name={'Estimated Price'} icono={'price'} />
                <input style={{ textAlign: 'center' }} readOnly type='text' value={ estimatePrice } />
            </div>
        </div>
        {isLoading ?
            <div className='cont-btn-loading'>
                <Puff
                    visible={true}
                    height="40"
                    width="40"
                    color="#E51F5F"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <p>Processing...</p>
            </div>
            : <div className='cont-btn-book'>
                <button className='btn-send'>Book Now</button>
                <p>Get <strong>10% Discount</strong>, Ask Us How! </p>
            </div>}
    </form>
}