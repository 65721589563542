export const preciosTlcPrincipal = {
  Dog: {
    Small: {
      basicGrooming: { price: 62, estimated: '$62' },
      deShedding: { price: 75, estimated: '$62 - $75' },
      fullGrooming: { price: 75, estimated: '$62 - $75' }
    },
    Medium: {
      basicGrooming: { price: 78, estimated: '$78' },
      deShedding: { price: 90, estimated: '$78 - $90' },
      fullGrooming: { price: 90, estimated: '$78 - $90' }
    },
    Large: {
      basicGrooming: { price: 94, estimated: '$94' },
      deShedding: { price: 110, estimated: '$94 - $110' },
      fullGrooming: { price: 110, estimated: '$94 - $110' }
    },
    Xlarge: {
      basicGrooming: { price: 120, estimated: '$120' },
      deShedding: { price: 130, estimated: '$120 - $130' },
      fullGrooming: { price: 130, estimated: '$120 - $130' },
    }
  },
  Cat: {
    Small: {
      basicGrooming: { price: 80, estimated: '$80' },
      fullGrooming: { price: 120, estimated: '$80 - $120' }
    },
    Medium: {
      basicGrooming: { price: 90, estimated: '$90' },
      fullGrooming: { price: 135, estimated: '$90 - $135' }
    }
  }
}

export const preciosTlcPalmBeach = {
  Dog: {
    Small: {
      basicGrooming: { price: 65, estimated: '$65' },
      deShedding: { price: 85, estimated: '$65 - $85' },
      fullGrooming: { price: 85, estimated: '$65 - $85' }
    },
    Medium: {
      basicGrooming: { price: 80, estimated: '$80' },
      deShedding: { price: 100, estimated: '$80 - $100' },
      fullGrooming: { price: 95, estimated: '$80 - $95' }
    },
    Large: {
      basicGrooming: { price: 100, estimated: '$100' },
      deShedding: { price: 130, estimated: '$100 - $120' },
      fullGrooming: { price: 130, estimated: '$100 - $130' }
    },
    Xlarge: {
      basicGrooming: { price: 120, estimated: '$120' },
      deShedding: { price: 140, estimated: '$120 - $140' },
      fullGrooming: { price: 145, estimated: '$120 - $145' }
    }
  },
  Cat: {
    Small: {
      basicGrooming: { price: 100, estimated: '$100' },
      fullGrooming: { price: 130, estimated: '$100 - $130' }
    },
    Medium: {
      basicGrooming: { price: 125, estimated: '$125' },
      fullGrooming: { price: 155, estimated: '$125 - $155' }
    }
  }
}

export const petSizeslist = [
  {type: 'Dog', name: 'Small 1-15 Pounds', clave: 'Small'},
  {type: 'Dog', name: 'Medium 16-40 Pounds', clave: 'Medium'},
  {type: 'Dog', name: 'Large 41-70 Pounds', clave: 'Large'},
  {type: 'Dog', name: 'X-Lg 71-100 Pounds', clave: 'Xlarge'},
  {type: 'Cat', name: 'Small 1-15 Pounds', clave: 'Small'},
  {type: 'Cat', name: 'Medium 16-40 Pounds', clave: 'Medium'}
];

export const servicesList = [
  {type: 'Dog', name: 'Full Grooming', clave: 'full-grooming'},
  {type: 'Dog', name: 'De-shedding', clave: 'de-shedding'},
  {type: 'Dog', name: 'Basic Grooming', clave: 'basic-grooming'},
  {type: 'Cat', name: 'Full Grooming', clave: 'full-grooming'},
  {type: 'Cat', name: 'Basic Grooming', clave: 'basic-grooming'}
]